<template>
  <div>
    <!-- Navigation -->
    <header class="header-shadow ">
      <sub-header />
      <!-- Middle navbar -->
      <nav
        class="d-none d-lg-flex navbar navbar-expand-lg navbar-light bg-white subheading medium"
        id="navbar"
      >
        <router-link class="navbar-brand bold" to="/">
          <img
            src="../assets/logo.png"
            alt=""
            class=""
            style="width:45px!important;"
          />
        </router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <!-- Left side -->
          <ul class="navbar-nav ml-auto">
            <li class="nav-item w-100">
              <input
                type="text"
                class="medium text-muted text-center border-0"
                placeholder="Entrez: Artiste, oeuvre, galerie ou mot-clé ..."
                style="min-width:400px;font-size:18px;"
                autofocus
                v-model.trim="search"
                @keyup.enter="onSearch"
              />
            </li>
          </ul>
          <!-- Right side -->
          <ul class="navbar-nav ml-auto">
            <li class="nav-item">
              <a
                class="nav-link body-1 _flex c-pointer"
                @click="$router.back()"
              >
                <i class="icon-exit mr-1 bold" style="font-size:12px;"></i>
                Fermer
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <!-- Mobile Menu -->
      <nav
        class="d-block d-lg-none navbar navbar-light bg-white subheading medium"
      >
        <div class="_flex _between">
          <div class="_flex">
            <a href="" class="mr-5">
              <div class="bar1"></div>
              <div class="bar2"></div>
              <div class="bar3"></div>
            </a>
            <a href="" class=""><i class="icon-search mr-1 title bold"></i></a>
          </div>
          <div class="_flex">
            <a class="navbar-brand bold" href="#">Ekekos</a>
          </div>
          <div class="_flex">
            <a href="" class="nav-link"
              ><i class="icon-cart title normal"></i
            ></a>
          </div>
        </div>
      </nav>
    </header>

    <!-- Header -->
    <section class="border-bottom">
      <div class="py-10 py-15-md py-20-lg container">
        <div class="row">
          <div
            class="col-12 col-md-10 col-lg-8 col-xl-6 offset-md-1 offset-lg-2 offset-xl-3 text-center"
          >
            <h4 class="bold">Les recherches les plus populaires</h4>
            <div class="_flex _wrap _center my-5">
              <div class="suggestion_box">JonOne</div>
              <div class="suggestion_box">Shepard Obey Fairey</div>
              <div class="suggestion_box">Phillippe Pasqua</div>
              <div class="suggestion_box">Abstrait</div>
              <div class="suggestion_box">Noir et blanc</div>
              <div class="suggestion_box">Sculpture bronze</div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <main-footer />
  </div>
</template>

<script>
import SubHeader from "@/components/headers/SubHeader.vue";
export default {
  data: () => ({
    search: "",
  }),
  components: { SubHeader },
  methods: {
    onSearch() {
      this.$router.push({
        path: "/resultats",
        query: {
          recherche: this.search,
        },
      });
    },
  },
};
</script>

<style></style>
